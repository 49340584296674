export type FigmaSelectionState = {
    selectedFrames: string[];
    selectedPage: string | undefined;
    isLimitReached: boolean;
};

export const FIGMA_FRAMES_TOGGLE = 'FIGMA_FRAMES_TOGGLE';
export const FIGMA_PAGE_SELECTION = 'FIGMA_PAGE_SELECTION';
export const FIGMA_CLEAR_SELECTION = 'FIGMA_CLEAR_SELECTION';
export const FIGMA_CLOSE_LIMIT_REACHED_MODAL = 'FIGMA_CLOSE_LIMIT_REACHED_MODAL';
