import * as types from '../types';

const initialState = {
    creativeId: -1,
    template: null,
    saved: true,
    textStyles: [],
    contentProperties: [],
    generateSuccess: false,
    generateStatus: '',
    isInAction: false,
    selectedShotIndex: -1,
    elements: [],
    selectedElementIds: [],
    shots: [],
    duration: 0,
    canPreview: true,
    resolutions: [],
    allowValidate: false,
    currentFrame: 0,
    canvasZoomLevel: 0,
    defaultRenditionProfileId: null,
};

export default function creativesReducer(state = initialState, action) {
    switch (action.type) {
        case types.CREATIVE_EDITOR_RESET:
            return {
                ...initialState,
            };
        case types.SET_EDITOR_SHOT:
            return {
                ...state,
                selectedShotIndex: action.index,
                currentFrame: typeof action.currentFrame === 'number' ? action.currentFrame : state.currentFrame,
            };
        case types.SET_EDITOR_ELEMENTS: {
            return {
                ...state,
                saved: action.saved,
                elements: action.elements,
                showAssetSelection:
                    action.showAssetSelection === undefined ? state.showAssetSelection : action.showAssetSelection,
            };
        }
        case types.GET_EDITOR_TEMPLATE: {
            const elements = action.template.pages.reduce((acc, page) => {
                return [...acc, ...page.elements];
            }, []);

            return {
                ...state,
                creativeId: action.creativeId,
                template: action.template,
                duration: action.template.duration,
                shots: action.template.shots,
                elements,
                _template: action._template,
                variationSetsValues: action.variationSetsValues,
                fonts: action.fonts,
                textStyles: action.textStyles,
                contentProperties: action.contentProperties,
                elementsFonts: action.elementsFonts,
                saved: true,
                generateSuccess: false,
                animations: action.animations,
                editorType: action.editorType,
                defaultRenditionProfileId: action.defaultRenditionProfileId || null,
            };
        }
        case types.DISCARD_EDITOR_TEMPLATE:
            return {
                ...state,
                template: action.template,
                duration: action.template.duration,
                shots: action.template.shots,
                elements: action.template.elements,
                saved: true,
            };
        case types.GET_FONTS_LIST:
            return {
                ...state,
                fonts: action.fonts,
            };
        case types.SET_SAVE_STATUS:
            return {
                ...state,
                canPreview: action.saved ? true : state.canPreview,
                saved: action.saved,
                elementsFonts: action.elementsFonts ? action.elementsFonts : state.elementsFonts,
                template: action.template ? action.template : state.template,
                duration: action.template ? action.template.duration : state.duration,
                shots: action.template ? action.template.shots : state.shots,
                selectedShotIndex:
                    typeof action.selectedShotIndex === 'number' ? action.selectedShotIndex : state.selectedShotIndex,
                selectedElementIds: action.selectedElementIds ? action.selectedElementIds : state.selectedElementIds,
                elements: action.elements ? action.elements : state.elements,
            };
        case types.PUBLISH_EDITOR_TEMPLATE:
            return {
                ...state,
                published: true,
                canPreview: true,
                redirect: action.redirect,
            };
        case types.SET_GENERATION_STATUS:
            return {
                ...state,
                generateStatus: action.status,
            };
        case types.SET_BATCH_GENERATION_PROGRESS:
            return {
                ...state,
                generationProgress: action.progress,
            };
        case types.EDITOR_TEMPLATE_ACTION_REQUEST:
            return {
                ...state,
                isInAction: true,
            };
        case types.EDITOR_TEMPLATE_ACTION_FAILURE:
            return {
                ...state,
                redirect: action.redirect,
            };
        case types.EDITOR_TEMPLATE_ACTION_FINISH:
            return {
                ...state,
                isInAction: false,
            };
        case types.EDITOR_SET_RTE_FONT:
            return {
                ...state,
                rteStyle: action.rteStyle,
                needToExec: !action.isSelection,
                isTextSelected: !action.isBlur,
            };
        case types.TOGGLE_ALLOW_VALIDATE:
            return {
                ...state,
                allowValidate: action.allowValidate,
            };
        case types.EDITOR_SET_SHOW_ASSETS_SELECTION:
            return {
                ...state,
                showAssetSelection: action.show,
            };
        case types.EDITOR_SET_TIMELINE_ZOOM:
            return {
                ...state,
                timlineZoom: action.timelineZoom,
            };
        case types.MARK_AS_UNSAVED:
            return {
                ...state,
                saved: false,
            };
        default:
            return state;
    }
}
