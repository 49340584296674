import {
    AmplitudeEventParams,
    AmplitudeEvents,
    VariationAdditionalProperties,
    CommonDesignEventActionParams,
    ExportAdditionalProperties,
    BlankDesignProperties,
} from '~/store/amplitude/types';
import { AMPLITUDE_TYPES } from '~/store/amplitude/constants';
import { getDesignProperties, getUserProperties, throwAmplitudeError } from '~/store/amplitude/utils';

// TODO: No information about all pages, we can only collect the first one
export const sendSharingCreateDesignEvent = () => (dispatch, getState) => {
    const store = getState();
    const {
        sharing: { entityId },
    } = store;
    const { creatives } = store.creatives;
    const creative = creatives.find((c) => c.id === entityId);

    const data = {
        ...getUserProperties(store),
        ...getDesignProperties(creative),
    };

    dispatch(sendAmplitudeEvent<'designEvent'>(AMPLITUDE_TYPES.SHARE_DESIGN, { ...data }));
};

export const sendAmplitudeDesignEvent =
    <T>({ eventType, design = null, additionalProps = {} }: CommonDesignEventActionParams<T>) =>
    (dispatch, getState) => {
        try {
            const store = getState();
            const data = {
                ...getUserProperties(store),
                ...getDesignProperties((design || store.creatives.creative) as any),
                ...additionalProps,
            };

            dispatch(sendAmplitudeEvent<'designEvent'>(eventType, data));
        } catch (error) {
            throwAmplitudeError(error);
        }
    };

export const sendAmplitudeNewDesignEvent = (params: CommonDesignEventActionParams<BlankDesignProperties>) =>
    sendAmplitudeDesignEvent(params);

export const sendAmplitudeVariationEvent = (params: CommonDesignEventActionParams<VariationAdditionalProperties>) =>
    sendAmplitudeDesignEvent(params);

export const sendAmplitudeExportEvent = (params: CommonDesignEventActionParams<ExportAdditionalProperties>) =>
    sendAmplitudeDesignEvent(params);

export function sendAmplitudeUserData(userData) {
    return (dispatch, getState) => {
        const { isStandalone, hostPort } = getState().platform;

        if (isStandalone) {
            return;
        }

        type AmplitudeUserData = {
            'Studio profile': string[];
            'Studio account ID': string;
            'Studio account type': string;
        };

        const data: AmplitudeUserData = {
            'Studio profile': userData.roles,
            'Studio account ID': userData.company,
            'Studio account type': userData.companies.find((item) => item.id === userData.company).accountType,
        };

        hostPort.postMessage({
            type: 'init_amplitude',
            data,
        });
    };
}

export function sendAmplitudeEvent<T extends AmplitudeEvents>(
    eventType: AMPLITUDE_TYPES,
    props: AmplitudeEventParams[T],
) {
    return (dispatch, getState) => {
        const { isStandalone, hostPort } = getState().platform;

        if (isStandalone) {
            return;
        }

        hostPort.postMessage({
            type: 'track',
            title: eventType,
            props,
        });
    };
}
