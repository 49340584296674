import * as types from './types';
import { FigmaSelectionState } from './types';

export const SELECTED_FRAMES_LIMIT = 20;

const initialState: FigmaSelectionState = {
    selectedFrames: [],
    selectedPage: undefined,
    isLimitReached: false,
};

export default function figmaSelectedFramesReducer(state = initialState, action) {
    switch (action.type) {
        case types.FIGMA_FRAMES_TOGGLE: {
            const id: string = action.id;
            const isCurrentlySelected = state.selectedFrames.includes(id);
            const isLimitReached = state.selectedFrames.length >= SELECTED_FRAMES_LIMIT && !isCurrentlySelected;

            let selectedFrames = state.selectedFrames;

            if (!isLimitReached) {
                selectedFrames = isCurrentlySelected
                    ? state.selectedFrames.filter((frameId) => frameId !== id)
                    : [...state.selectedFrames, id];
            }

            return {
                ...state,
                selectedFrames,
                isLimitReached,
            };
        }
        case types.FIGMA_PAGE_SELECTION: {
            if (state.selectedPage === action.id) {
                return state;
            }

            return {
                ...state,
                selectedPage: action.id,
                isLimitReached: false,
                selectedFrames: [],
            };
        }
        case types.FIGMA_CLOSE_LIMIT_REACHED_MODAL: {
            return {
                ...state,
                isLimitReached: false,
            };
        }
        case types.FIGMA_CLEAR_SELECTION: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
