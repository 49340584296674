import * as types from '../types';
import isInIframe from '../../helpers/iframeHelper';
import { loginBynderUser, sendRefreshCommand } from '../user/user.actions';

export function setPlatformOptions(options) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_PLATFORM_OPTIONS,
            options,
        });
    };
}

export function checkPlatformUsage() {
    return (dispatch) => {
        if (isInIframe()) {
            const portalDomain = new URL(window.document.referrer).hostname;
            dispatch({
                type: types.UPDATE_PLATFORM_OPTIONS,
                options: {
                    isStandalone: false,
                    portalDomain: portalDomain === 'localhost' ? 'dam.bynder.com' : portalDomain,
                },
            });
            window.addEventListener('message', function (e) {
                if (e.ports && e.ports.length > 0) {
                    e.ports[0].onmessage = function (e) {
                        dispatch(onMessage(e));
                    };
                    e.ports[0].postMessage({
                        type: 'channel_set',
                    });
                    if (APPCUES_ENABLED && window.Appcues) {
                        e.ports[0].postMessage({
                            type: 'appcues',
                        });
                    }
                    dispatch({
                        type: types.UPDATE_PLATFORM_OPTIONS,
                        options: {
                            hostPort: e.ports[0],
                        },
                    });
                }
            });
        } else {
            dispatch({
                type: types.UPDATE_PLATFORM_OPTIONS,
                options: {
                    isStandalone: true,
                },
            });
        }
    };
}

let authRefreshTimer = 0;

function onMessage(e) {
    return (dispatch) => {
        // not used right now, but might be useful later
        // if (e.data.type === 'appcues_init') {
        //     AuthorizationHelper.setAppcuesId(e.data.data.id);
        // }
        if (e.data.type === 'access_token') {
            if (!e.data.data) {
                console.error('No access token received from the platform.');
                return;
            }
            if (authRefreshTimer) {
                clearTimeout(authRefreshTimer);
            }
            dispatch({
                type: types.UPDATE_PLATFORM_OPTIONS,
                options: {
                    bynderAccessToken: e.data.data,
                    hostUrl: e.data.hostUrl,
                },
            });
            dispatch(loginBynderUser());
            authRefreshTimer = setTimeout(() => {
                authRefreshTimer = 0;
                dispatch(sendRefreshCommand());
            }, AUTH_REFRESH_TIMEOUT);
        }
    };
}
