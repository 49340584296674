import { addHours, isAfter } from 'date-fns';
import { notify } from '@bynder/design-system';
import GroupsService from 'packages/services/GroupsService';
import FonstService from 'packages/services/FontsService';
import TrackingService from 'packages/services/TrackingService';
import filtersStorage from 'packages/hooks/filtersStorage';
import { catchResponseError } from 'packages/helpers/helpers';
import { sendAmplitudeUserData } from '~/store/amplitude/actions';
import roles from '~/configs/roles';
import * as types from '../types';
import UserService from '../../services/userService';
import AuthService from '../../services/authService';
import { clearCalendar } from '../general/general.actions';
import AuthorizationHelper from '../../helpers/AuthorizationHelper';
import AuthenticationHelper from '../../helpers/AuthenticationHelper';
import isInIframe from '../../helpers/iframeHelper';
import ProxyServerConnector from '../../services/connectors/ProxyServerConnector';

export default {
    login,
    resetConfirm,
    getUser,
    validateToken,
    activateAccount,
    setDefaults,
};

const { getFiltersFromStorage, setFiltersToStorage } = filtersStorage();

export function getUser() {
    return async (dispatch) => {
        try {
            const { status, json: data } = await UserService.getUser();

            if (status === 200 && data) {
                AuthorizationHelper.setPermissions(data.permissions, data.company);
                AuthorizationHelper.setRoles(data.roles);
                AuthorizationHelper.setFeatures(data.accessibleFeatures);

                const currentCompanyData = data.companies.find((company) => company.id === data.company);
                AuthorizationHelper.appcuesIdentify(data.user, currentCompanyData, data.roles, data.accessibleFeatures);

                dispatch(getUserFonts(data.company));

                dispatch({
                    type: types.LOGIN_SUCCESS,
                    user: data.user,
                    companies: data.companies,
                    currentCompany: data.company,
                    permissions: data.permissions,
                    roles: data.roles,
                    accessibleFeatures: data.accessibleFeatures,
                });

                dispatch({
                    type: types.TOGGLE_APP_INITIALIZING,
                    state: false,
                });

                dispatch(sendAmplitudeUserData(data));

                const storedUser = getFiltersFromStorage('loginTracking');

                if (storedUser) {
                    if (isAfter(new Date(), addHours(new Date(storedUser.currentDateTime), 1))) {
                        await TrackingService.registerLogin({
                            bynderUserId: data.user.bynderUserId,
                            bynderAccountId: data.companies[0].bynderAccountId,
                        });

                        setFiltersToStorage('loginTracking', {
                            bynderUserId: data.user.bynderUserId,
                            bynderAccountId: data.companies[0].bynderAccountId,
                            currentDateTime: new Date(),
                        });
                    }
                } else {
                    await TrackingService.registerLogin({
                        bynderUserId: data.user.bynderUserId,
                        bynderAccountId: data.companies[0].bynderAccountId,
                    });

                    setFiltersToStorage('loginTracking', {
                        bynderUserId: data.user.bynderUserId,
                        bynderAccountId: data.companies[0].bynderAccountId,
                        currentDateTime: new Date(),
                    });
                }

                if (
                    !(
                        data.accessibleFeatures.includes('trial_enabled') &&
                        data.roles.includes(roles.video_brand_studio.video.manage)
                    )
                ) {
                    dispatch(getGroups());
                }
            }
        } catch (err) {
            catchResponseError(err);
        }
    };
}

export function login(email, password) {
    return (dispatch) => {
        dispatch({ type: types.LOGIN_REQUEST, email });
        AuthService.login({ email, password })
            .then(({ json, status }) => {
                if (status === 429) {
                    notify({
                        title: 'Too many login attempts, please wait one minute and try again.',
                        variant: 'error',
                    });
                } else if (status !== 200) {
                    dispatch({ type: types.LOGIN_FAIL });
                } else {
                    AuthenticationHelper.setToken(json.identityAccessToken);

                    notify.dismiss();
                    dispatch(clearCalendar());
                    dispatch(getCompanies());
                }
            })
            .catch(catchResponseError);
    };
}

export function loginBynderUser() {
    return (dispatch, getState) => {
        const { portalDomain, bynderAccessToken } = getState().platform;

        if (!bynderAccessToken) {
            console.error('loginBynderUser: No bynder access token.');

            return;
        }

        AuthService.exchangeBynderToIdentityToken({
            portalDomain,
            accessToken: bynderAccessToken,
        })
            .then((authResponse) => {
                if (authResponse.status === 200) {
                    if (authResponse.json.loginUrl) {
                        if (isInIframe()) {
                            dispatch(sendRedirectCommand(authResponse.json.loginUrl));
                        } else {
                            window.location.href = authResponse.json.loginUrl;
                        }

                        return null;
                    }

                    dispatch({
                        type: types.UPDATE_PLATFORM_OPTIONS,
                        options: {
                            accessToken: authResponse.json.identityAccessToken,
                        },
                    });

                    dispatch(getUser());

                    ProxyServerConnector.flushWaitList();
                }

                return null;
            })
            .catch(catchResponseError);
    };
}

export function registerBynderUser(authCode, state, cb) {
    return async (dispatch) => {
        dispatch({
            type: types.UPDATE_PLATFORM_OPTIONS,
            options: {
                bynderAccessToken: null,
            },
        });

        try {
            const {
                json: { bynderAccessToken },
                status,
            } = await AuthService.registerBynderUser({ authCode, state });

            if (status === 200) {
                dispatch({
                    type: types.UPDATE_PLATFORM_OPTIONS,
                    options: {
                        bynderAccessToken,
                    },
                });

                if (cb) {
                    cb();
                }
            }
        } catch (err) {
            catchResponseError(err);
        }
    };
}

function getCompanies() {
    return (dispatch) => {
        dispatch({ type: types.GET_COMPANIES_REQUEST });
        UserService.getCompanies()
            .then(({ companies }) => {
                dispatch({ type: types.GET_COMPANIES_SUCCESS, companies });

                if (companies.length === 1) {
                    dispatch(selectCompany(companies[0]));
                }
            })
            .catch(catchResponseError);
    };
}

function getGroups() {
    return (dispatch) => {
        dispatch({ type: types.GET_GROUPS_REQUEST });
        GroupsService.getGroups()
            .then(({ json }) => {
                dispatch({ type: types.GET_GROUPS_SUCCESS, groups: json.items });
            })
            .catch(catchResponseError);
    };
}

function getUserFonts(currentCompanyId) {
    return (dispatch) => {
        dispatch({ type: types.GET_FONTS_REQUEST });

        FonstService.getFonts(currentCompanyId)
            .then(({ json }) => {
                dispatch({ type: types.GET_FONTS_SUCCESS, items: json });
            })
            .catch(catchResponseError);
    };
}

export function logout() {
    return (dispatch, getState) => {
        const { isStandalone, hostPort } = getState().platform;

        if (isStandalone) {
            AuthenticationHelper.removeToken();

            dispatch(clearCalendar());
            window.location = '/login';
        } else {
            hostPort.postMessage({
                type: 'logout',
            });
            setFiltersToStorage('loginTracking', '');
        }

        dispatch({ type: types.LOGOUT });
    };
}

export function sendRefreshCommand() {
    return (dispatch, getState) => {
        const { isStandalone, hostPort } = getState().platform;

        if (!isStandalone) {
            hostPort.postMessage({
                type: 'refresh_token',
            });
            setFiltersToStorage('loginTracking', {
                ...getFiltersFromStorage('loginTracking'),
                currentDateTime: new Date(),
            });
        }
    };
}

function sendRedirectCommand(url) {
    return (dispatch, getState) => {
        const { isStandalone, hostPort } = getState().platform;

        if (!isStandalone) {
            hostPort.postMessage({
                type: 'redirect',
                url,
            });
        }
    };
}

export function selectCompany(company) {
    return async (dispatch) => {
        dispatch({ type: types.SELECT_COMPANY_REQUEST });

        try {
            const selectCompanyResponse = await UserService.selectCompany(company.id);

            if (selectCompanyResponse.status === 200) {
                const exchangeTokenResponse = await AuthService.exchangeToken(company.id);

                if (exchangeTokenResponse.status === 200) {
                    AuthenticationHelper.setToken(exchangeTokenResponse.json.identityAccessToken);
                    dispatch(getUser());

                    return true;
                }
            }
        } catch (err) {
            catchResponseError(err);
        }

        dispatch(logout());
    };
}

export function logoutRequest() {
    return (dispatch) => {
        dispatch({ type: types.LOGOUT_REQUEST });
        AuthService.logout()
            .then(
                (s) => {
                    s.status === 200 && dispatch(logout());
                },
                () => {
                    alert('fail');
                },
            )
            .catch(catchResponseError);
    };
}

export function validateToken(token) {
    return (dispatch) => {
        AuthService.validateToken(token)
            .then(({ email, status }) => {
                if (status === 200 && email) {
                    dispatch({ type: types.VALIDATE_TOKEN_SUCCESS, email });
                } else {
                    dispatch({ type: types.VALIDATE_TOKEN_FAIL });
                }
            })
            .catch(catchResponseError);
    };
}

export function resetRequest(email) {
    return (dispatch) => {
        dispatch({ type: types.RESET_REQUEST });
        AuthService.resetRequest({ email })
            .then((status) => {
                if (status === 200) {
                    dispatch({ type: types.RESET_REQUEST_SUCCESS });
                } else {
                    notify({
                        title: 'Oops!. Something went wrong. Please try again.',
                        variant: 'error',
                    });
                    // (dispatch({type: types.RESET_REQUEST_FAIL, resetRequestFail: {status, msg: 'Wrong Email'}}))
                }
            })
            .catch(catchResponseError);
    };
}

export function setDefaults() {
    return { type: types.USER_SET_DEFAULTS };
}

export function resetConfirm(email, password, token) {
    return (dispatch) => {
        AuthService.resetConfirm({ email, password, token })
            .then((status) => {
                if (status === 200) {
                    dispatch({ type: types.RESET_SUCCESS });
                }
            })
            .catch(catchResponseError);
    };
}

export function activateAccount(token, password, firstName, lastName) {
    return (dispatch) => {
        dispatch({ type: types.USER_ACTIVATE_REQUEST });

        return AuthService.activate({ token, password, firstName, lastName })
            .then((status) => {
                if (status === 200) {
                    dispatch({ type: types.USER_ACTIVATE_SUCCESS });
                    notify({
                        title: 'Your account has been created! Please login to get started.',
                        variant: 'success',
                    });
                } else {
                    dispatch({ type: types.USER_ACTIVATE_FAILURE });
                    notify({
                        title: 'Sorry, there appears to be a problem with your request. Please try again.',
                        variant: 'error',
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function hideBackToOldMessage() {
    return { type: types.USER_BACK_TO_OLD };
}
