import { notify } from '@bynder/design-system';
import { catchResponseError } from 'packages/helpers/helpers';
import * as types from './../types';
import ProxyServerConnector from '../../services/connectors/ProxyServerConnector';
import { createCookie, eraseCookie } from '../../helpers/cookie';

const request = new ProxyServerConnector('');

export default {
    changePage,
    editTitle,
};
export function clearCalendar() {
    return (dispatch) => {
        eraseCookie('calendar');
        dispatch({ type: types.CALENDAR_CHANGE, calendar: {} });
    };
}

export function calendarChange(dates) {
    return (dispatch) => {
        createCookie('calendar', JSON.stringify(dates));
        dispatch({ type: types.CALENDAR_CHANGE, calendar: dates });
    };
}

export function editTitle({ value, path, cb }) {
    return (dispatch, getState) => {
        const other = getState().general.currentPage.editable.other;

        if (other && other.callback) {
            dispatch(other.callback({ name: value, id: other.id }));
        } else {
            request
                .put(path, { name: value, ...other })
                .then(({ status }) => {
                    if (status === 200) {
                        cb && dispatch(cb(value));
                        dispatch({ type: types.EDIT_TITLE_SUCCESS, value });
                    } else {
                        notify({
                            title: 'This name already exists in the list. Please choose a different name',
                            variant: 'error',
                        });
                        dispatch({ type: types.EDIT_TITLE_FAIL });
                    }
                })
                .catch(catchResponseError);
        }
    };
}

export function handleToggleSwitch(checked) {
    return (dispatch) => {
        dispatch({ type: types.TOGGLE_STATUS_SWITCH, checked });
    };
}

export function changePage(page) {
    return (dispatch) => {
        dispatch({
            type: types.PAGE_CHANGE,
            page,
        });
        dispatch({ type: types.SET_STATUS_SWITCH, toggleItemStatus: page.toggleItemStatus || null });
    };
}
