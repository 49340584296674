import ProxyServerConnector from './connectors/ProxyServerConnector';

type ParsedDirectToken = {
    exp: number;
};

const parseJWT = (token: string) => {
    if (!token) {
        return null;
    }

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
};

class AuthService extends ProxyServerConnector {
    directToken = '';

    parsedDirectToken: ParsedDirectToken | null = null;

    directTokenPendingRef: Promise<string> | null = null;

    constructor() {
        super('');
    }

    private setCurrentToken(token: string) {
        this.directToken = token;
        this.parsedDirectToken = parseJWT(token);
        this.directTokenPendingRef = null;
    }

    private isDirectTokenValid = () => {
        if (this.parsedDirectToken === null) {
            return false;
        }

        const { exp } = this.parsedDirectToken;
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        const leftTimeToExpire = exp - currentTimeInSeconds;
        const allowedTimeUntilExpire = 60 * 20;

        return leftTimeToExpire > allowedTimeUntilExpire;
    };

    getDirectToken = (bynderToken: string): Promise<string> => {
        if (this.directToken && this.isDirectTokenValid()) {
            return Promise.resolve(this.directToken);
        }

        if (this.directTokenPendingRef) {
            return this.directTokenPendingRef;
        }

        this.directTokenPendingRef = this.rotateToken(bynderToken).then(({ json, status }) => {
            if (status === 200) {
                this.setCurrentToken(json.accessToken);

                return this.directToken;
            }

            this.setCurrentToken('');

            return '';
        });

        return this.directTokenPendingRef;
    };

    login(data) {
        return this.post('/auth/login', data);
    }

    refreshToken() {
        return this.get('/auth/refresh');
    }

    exchangeToken(customerId) {
        return this.post('/auth/exchangeToken', { customerId });
    }

    private rotateToken(accessToken: string) {
        return this.post<{ accessToken: string }>('/auth/rotate-token', { accessToken });
    }

    logout() {
        return this.get('/auth/logout');
    }

    resetRequest(data) {
        return this.post('/secret-reset/request-token', data).then(({ status }) => {
            return status;
        });
    }

    validateToken(data) {
        return this.get('/secret-reset/validate/?token=' + data).then(({ json, status }) => {
            return {
                status,
                email: json.email,
            };
        });
    }

    resetConfirm(data) {
        const { password, email, token } = data;

        return this.post('/secret-reset/secret', { email, password, token }).then(({ status }) => {
            return status;
        });
    }

    activate(data) {
        return this.post('/auth/activate', data).then(({ status }) => {
            return status;
        });
    }

    exchangeBynderToIdentityToken(data) {
        return this.post('/auth/exchangeBynderToIdentityToken', data);
    }

    registerBynderUser(data) {
        return this.post('/auth/registerBynderUser', data);
    }

    checkToken() {
        return this.get('/auth/check-token');
    }
}

export default new AuthService();
