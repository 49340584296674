import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getUser as getUserSelector } from 'packages/store/user/user.selectors';
import { isStandaloneSelector } from 'packages/store/platform/platform.selectors';
import { getUser as getUserAction, selectCompany } from '~/store/user/user.actions';
import AuthenticationHelper from '~/helpers/AuthenticationHelper';
import Loader from '~/common/loader/Loader';
import generateTestId from '~/helpers/testIdHelpers';

const PrivateRoute = (props) => {
    const dispatch = useDispatch();
    const loaderTestId = generateTestId('old-page-loader');

    const { component: RouteComponent, ...rest } = props;

    const { user, currentCompany } = useSelector(getUserSelector);
    const isStandalone = useSelector(isStandaloneSelector);

    const customerIdInPath = parseInt(props.location.pathname.split('/')[1], 10) || 0;
    const hasToken = AuthenticationHelper.isLoggedIn();

    if (
        !user &&
        !hasToken &&
        props.location.pathname !== '/' &&
        (props.location.pathname !== '/designs' ||
            (props.location.pathname === '/designs' && props.location.search !== '')) &&
        props.location.pathname !== '/shared'
    ) {
        if (isStandalone) {
            dispatch(getUserAction());
        }

        // TODO: when all pages will be covered by skeleton this if sections can be deprecated
        return (
            <div className="w-100 h-100 d-flex align-items-center justify-content-center" {...loaderTestId}>
                <Loader size={50} />
            </div>
        );
    } else if (!user) {
        if (isStandalone && !hasToken) {
            return <Redirect to="/login" />;
        }

        if (
            hasToken &&
            props.location.pathname !== '/' &&
            (props.location.pathname !== '/designs' ||
                (props.location.pathname === '/designs' && props.location.search !== '')) &&
            props.location.pathname !== '/shared'
        ) {
            if (isStandalone) {
                dispatch(getUserAction());
            }

            // TODO: when all pages will be covered by skeleton this if sections can be deprecated
            return (
                <div className="w-100 h-100 d-flex align-items-center justify-content-center" {...loaderTestId}>
                    <Loader size={50} />
                </div>
            );
        }
    } else if (user && currentCompany && customerIdInPath && customerIdInPath !== props.currentCompany) {
        selectCompany({ id: customerIdInPath });
    }

    return <Route {...rest} render={(routeProps) => <RouteComponent {...routeProps} />} />;
};

export default PrivateRoute;
