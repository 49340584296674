import * as types from '../types';

export const initialState = {
    user: null,
    companies: [],
    permissions: [],
    roles: [],
    accessibleFeatures: [],

    resetRequestFail: null,
    updateFail: null,
    currentCompany: 0,
    timeZones: [],

    isActivating: false,
    isActivated: false,
    tokenValdiationStatus: 'REQUEST', // REQUEST || SUCCESS || FAIILURE
    groups: [],
    isShowBackToOldMessage: true,

    fonts: [],
    elementsFonts: [],
};
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case types.USER_SET_DEFAULTS:
            return {
                ...initialState,
            };
        case types.SELECT_COMPANY_REQUEST:
            return {
                ...state,
                currentCompany: 0,
            };
        case types.SELECT_COMPANY_SUCCESS:
            return {
                ...state,
                currentCompany: action.company,
            };
        case types.GET_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: action.companies,
            };
        case types.GET_GROUPS_REQUEST:
        case types.GET_GROUPS_FAILURE:
            return {
                ...state,
                groups: [],
            };
        case types.GET_GROUPS_SUCCESS:
            return {
                ...state,
                groups: action.groups,
            };
        case types.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                updateSuccess: true,
                user: { ...state.user, ...action.user },
            };
        case types.SET_UPDATE_USER_SUCCESS:
            return {
                ...state,
                updateSuccess: action.success,
                updateFail: action.fail,
            };
        case types.UPDATE_PROFILE_FAIL_PASS:
            return {
                ...state,
                updateSuccess: false,
                updateFail: { msg: 'Wrong password' },
            };
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                loggingIn: false,
                user: action.user,
                currentCompany: action.currentCompany || state.currentCompany,
                companies: action.companies,
                permissions: action.permissions,
                roles: action.roles,
                accessibleFeatures: action.accessibleFeatures,
                lastLoginFail: false,
            };
        case types.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case types.LOGIN_FAIL:
            return {
                ...state,
                loggingIn: false,
                lastLoginFail: true,
            };
        case types.LOGOUT_REQUEST:
            return {
                ...state,
                loggingOut: true,
            };
        case types.LOGOUT:
            return {
                ...state,
                loggingOut: false,
                user: null,
                companies: null,
                roles: null,
                permissions: null,
            };
        case types.RESET_REQUEST:
            return {
                ...state,
                requestingReset: true,
            };
        case types.RESET_REQUEST_SUCCESS:
            return {
                ...state,
                requestingReset: false,
                resetRequestSuccess: true,
                resetRequestFail: null,
            };
        case types.RESET_REQUEST_FAIL:
            return {
                ...state,
                requestingReset: false,
                resetRequestSuccess: false,
                resetRequestFail: action.resetRequestFail,
            };
        case types.VALIDATE_TOKEN_SUCCESS:
            return {
                ...state,
                tokenIsValid: true,
                resetEmail: action.email,
                tokenValdiationStatus: 'SUCCESS',
            };
        case types.VALIDATE_TOKEN_FAIL:
            return {
                ...state,
                tokenIsValid: false,
                tokenValdiationStatus: 'FAILURE',
            };
        case types.RESET_SUCCESS:
            return {
                ...state,
                requestingReset: true,
                resetSuccess: true,
            };
        case types.GET_TIMEZONES_SUCCESS:
            return {
                ...state,
                timeZones: action.timeZones,
            };
        case types.USER_ACTIVATE_REQUEST:
            return {
                ...state,
                isActivating: true,
            };
        case types.USER_ACTIVATE_SUCCESS:
            return {
                ...state,
                isActivating: false,
                isActivated: true,
            };
        case types.USER_BACK_TO_OLD:
            return {
                ...state,
                isShowBackToOldMessage: false,
            };
        case types.GET_FONTS_REQUEST:
            return {
                ...state,
                fonts: [],
                elementsFonts: [],
            };
        case types.GET_FONTS_SUCCESS:
            return {
                ...state,
                fonts: action.items.fonts,
                elementsFonts: action.items.elementsFonts,
            };
        default:
            return state;
    }
}
