import jwt_decode from 'jwt-decode';
import reduxStore from '../store';
import * as types from '../store/types';
import store from 'store2';

class AuthenticationHelper {
    getToken = (): string => {
        const { isStandalone, accessToken } = reduxStore.getState().platform;
        if (isStandalone) {
            return store.get('accessToken');
        } else {
            return accessToken;
        }
    };

    setToken = async (token: string): Promise<void> => {
        const { isStandalone } = reduxStore.getState().platform;

        if (isStandalone) {
            store.set('accessToken', token);
        } else {
            await reduxStore.dispatch({
                type: types.UPDATE_PLATFORM_OPTIONS,
                options: {
                    accessToken: token,
                },
            });
        }
    };

    removeToken = (): void => {
        const { isStandalone } = reduxStore.getState().platform;
        if (isStandalone) {
            return store.remove('accessToken');
        }
    };

    // TODO: This a big problem since token not always in place. Better to resolve it via BE
    getProfiles = () => {
        if (!this.getToken()) {
            return [];
        }
        const decoded = jwt_decode(this.getToken()) as any;
        return decoded && decoded.bynderProfileId ? decoded.bynderProfileId : [];
    };

    isLoggedIn = (): boolean => {
        const token = store.get('accessToken');
        return !!token;
    };
}

export default new AuthenticationHelper();
