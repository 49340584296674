import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

class FontsService extends ProxyServerConnector {
    constructor(path = '/font/customer/') {
        super(path);
        // @ts-ignore
        // eslint-disable-next-line no-undef
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    getFonts(customerId: number) {
        // @ts-ignore
        return this.gateway.get(`${customerId}/fonts`);
    }
}

export default new FontsService();
